import { useState,useLayoutEffect } from "react";

const useIsMobile = () => {
  const [isBelow962px, setIsBelow962px] = useState(window.innerWidth < 962);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsBelow962px(window.innerWidth < 962);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); 

  return isBelow962px;
};

export { useIsMobile };

const SidenavItem = ({ leftText, rightText, isLast }) => {
  const isTextString =
    typeof rightText === "string" || typeof rightText === "number";
  return (
    <div className={`sidenav__item${isLast ? " last" : ""}`}>
      <span className="sidenav__item-left">{leftText}</span>
      {isTextString ? (
        <span>{rightText}</span>
      ) : (
        <div className="sidenav__item-right">
          {rightText.map((text, i) => (
            <div key={i} className="sidenav__item-right__content">
              <span>{text}</span>
              {rightText.length - 1 !== i && <span className="circle" />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SidenavItem;

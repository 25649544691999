import { Carousel } from "react-responsive-carousel";
import { useIsMobile } from "../../utils";
import Icons from "../Icons/Icons";

const Gallery = ({ setGalleryIsOpen, selectedInvestment }) => {
  const isMobile = useIsMobile();
  const dataObj = selectedInvestment?.dataObject;
  if (!dataObj) return <></>;

  const { projectName, galleryCollection } = dataObj;

  return (
    <div className="gallery">
      <div className="gallery__title">
        <h2>{projectName}</h2>
        <button onClick={() => setGalleryIsOpen(false)}>
          <Icons.Close />
        </button>
      </div>
      <div className="gallery__carousel">
        {isMobile ? (
          <div className="gallery__carousel__mobile">
            {galleryCollection?.items.map((item, i) => (
              <img key={i} src={`${item.url}?h=600`} alt={item.title} />
            ))}
          </div>
        ) : (
          <Carousel
            showArrows={true}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            swipeable={true}
            useKeyboardArrows={true}
            emulateTouch={true}
            infiniteLoop={false}
            centerMode={true}
            centerSlidePercentage={70}
            renderArrowPrev={(onClickHandler, hasPrev) =>
              hasPrev && (
                <button className="prev" type="button" onClick={onClickHandler}>
                  <Icons.ArrowRightCarousel />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext) =>
              hasNext && (
                <button className="next" type="button" onClick={onClickHandler}>
                  <Icons.ArrowRightCarousel />
                </button>
              )
            }
          >
            {galleryCollection?.items.map((item, i) => (
              <img key={i} src={`${item.url}?h=800`} alt={item.title} />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default Gallery;

const getInvestments = async () => {
  try {
    let allItems = [];
    let skip = 0;
    const limit = 100; // You can adjust the limit as needed

    while (true) {
      const response = await fetch(
        `${process.env.REACT_APP_CONTENTFUL_URL}${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_TOKEN}`,
          },
          body: JSON.stringify({
            query: `
              query {
                investmentCollection(skip: ${skip}, limit: ${limit}) {
                  total
                  items {
                    projectName
                    category {
                      pluralName
                      singularName
                      icon {
                        url
                        title
                      }
                    }
                    galleryCollection {
                      total
                      items {
                        url
                        title
                      }
                    }
                    address
                    projectId
                    deliveryYear
                    totalCostLarge
                    aidAmmountLarge
                    description
                    poiCoordinates
                    gisData
                  }
                }
              }
            `,
          }),
        }
      );

      const responseData = await response.json();
      const items = responseData.data.investmentCollection.items;
      allItems = [...allItems, ...items];

      // If there are no more items to fetch, break out of the loop
      if (items.length < limit) {
        break;
      }

      // Increment the skip value for the next page
      skip += limit;
    }

    return allItems;
  } catch (error) {
    console.error("Hiba történt a lekérdezés során:", error);
    return []; // Return an empty array in case of error
  }
};

export default getInvestments;
// React imports
import React, { useRef, useState } from "react";
// Maps API
import { useLoadScript } from "@react-google-maps/api";
// use-places-autocomplete
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import Icons from "../Icons/Icons";
import SearchBarInvestment from "./SearchBarInvestment";
// Map Config
import { MapConfig } from "../Map/MapConfig";

// Create a bounding box to filter search bounds
const center = {
  lat: MapConfig.baseViewCenter[1],
  lng: MapConfig.baseViewCenter[0]
};
const radius = 1;
const defaultBounds = {
  north: center.lat + radius,
  south: center.lat - radius,
  east: center.lng + radius,
  west: center.lng - radius,
};

// Search settings
const SearchSettings = {
  libraries: ["places"],
  requestOptions: {
    types: ["address"],
    componentRestrictions: { country: "hu" },
    bounds: defaultBounds,
    strictBounds: true,
  },
};

// SEARCHBAR - GM API wrapper
const SearchBar = ({ setSearchBounds, investments, setClickedInvestment }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.NODE_ENV === 'production' ? 
      process.env.REACT_APP_GOOGLE_PLACES_API_KEY_PROD :
      process.env.REACT_APP_GOOGLE_PLACES_API_KEY_DEV,
    libraries: SearchSettings.libraries,
  });
  return !isLoaded || loadError ? null : (
    <SearchBarComponent
      setSearchBounds={setSearchBounds}
      investments={investments}
      setClickedInvestment={setClickedInvestment}
    />
  );
};

// SEARCHBAR COMPONENT
const SearchBarComponent = ({
  setSearchBounds,
  investments,
  setClickedInvestment,
}) => {
  const inputRef = useRef();
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState(null);

  // Send search request
  const FormSubmitHandler = async (e) => {
    e.preventDefault();

    // Open search
    if (!searchOpen) {
      setSearchOpen(true);
      inputRef.current.focus();
    }

    // If search input has value and isn't open
    if (searchOpen && searchText !== "" && searchText !== null) {
      // Get typed location from API
      try {
        const results = await getGeocode({
          address: searchText,
        });

        const { geometry } = results[0];
        const { viewport } = geometry;

        const NELat = viewport.getNorthEast().lat(),
          NELng = viewport.getNorthEast().lng(),
          SWLat = viewport.getSouthWest().lat(),
          SWLng = viewport.getSouthWest().lng();

        setSearchBounds([SWLng, SWLat, NELng, NELat]);

        // Clear input
        setValue("");
        setSearchText("");

        // Close search
        setSearchOpen(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Clear and close search bar
  const SearchBarDeleteHandler = () => {
    if (searchText === "" || searchText == null) setSearchOpen(false);
    if (searchText !== "") {
      setValue("");
      setSearchText("");
    }
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: SearchSettings.requestOptions,
    debounce: 500,
  });

  const filteredInvestments = () => {
    if (investments || investments !== null) {
      const items = investments.filter((investment) =>
        investment.projectName.toLowerCase().includes(searchText.toLowerCase())
      );
      return items;
    }
    return [];
  };

  const filteredSuggestions = () => {
    if (data) {
      const filteredData = data.filter((suggestion) =>
        suggestion.terms[0].value.toLowerCase().includes("debrecen")
      );
      return filteredData;
    }
    return [];
  }

  return (
    <div className={`search ${searchOpen ? "active" : ""}`}>
      <form onSubmit={FormSubmitHandler}>
        <label>
          <button type="submit">
            <Icons.Search />
          </button>
          <input
            ref={inputRef}
            type="text"
            placeholder="Keresés címre vagy beruházásra..."
            onChange={(e) => {
              setSearchText(e.target.value);
              setValue(e.target.value);
            }}
            value={value}
            disabled={!ready}
          />
          <ul className={`search__list ${status === "OK" && filteredInvestments().length > 0 ? "active" : ""}`}>
            {status === "OK" && (
              <>
                {filteredInvestments().length > 0 && (
                  <>
                    {filteredInvestments().map((item, i) => (
                      <SearchBarInvestment
                        item={item}
                        key={i}
                        setClickedInvestment={setClickedInvestment}
                        setSearchOpen={setSearchOpen}
                        setValue={setValue}
                      />
                    ))}

                    <hr />
                  </>
                )}

                {filteredSuggestions().map((suggestion) => {
                  return (
                    <button
                      type="submit"
                      onClick={() => {
                        setSearchText(suggestion.description);
                        setValue(suggestion.description);
                      }}
                      key={suggestion.place_id}
                    >
                      <Icons.Pin />
                      {suggestion.description}
                    </button>
                  );
                })}
              </>
            )}
          </ul>
          <button type="button" onClick={() => SearchBarDeleteHandler()}>
            <Icons.Close />
          </button>
        </label>
      </form>
    </div>
  );
};

export default SearchBar;

import mapImg from "../../assets/images/map.png";
import ortoImg from "../../assets/images/orto.png";

const MapSwitch = ({ isMapTypeOrto, setIsMapTypeOrto }) => {
  return (
    <div
      className="map-switch"
      onClick={() => setIsMapTypeOrto((prev) => !prev)}
    >
      <img src={isMapTypeOrto ? mapImg : ortoImg} alt="Térkép" />
    </div>
  );
};

export default MapSwitch;

import { Style, Icon, Fill, Stroke, Circle } from "ol/style";
import imageArrow from "../../assets/images/Arrow.svg";

export const lineStringStyle = new Style({
  stroke: new Stroke({
    color: "#FFED35",
    width: 6,
  }),
});
export const polygonStyle = new Style({
  stroke: new Stroke({
    color: "#FFED35",
    width: 2,
  }),
  fill: new Fill({
    color: "rgba(255, 237, 53, 0.5)",
  }),
});
const circleShadowStyle = (scaleValue) => {
  return new Style({
    image: new Circle({
      radius: 20 * scaleValue,
      stroke: new Stroke({
        color: "rgba(0,0,0,0.1)",
        width: 8,
      }),
      displacement: [0,-4 * scaleValue]
    }),
  });
};
const circleStyle = (scaleValue) => {
  return new Style({
    image: new Circle({
      radius: 20 * scaleValue,
      fill: new Fill({
        color: "rgba(255,255,255,1)",
      }),
      stroke: new Stroke({
        color: "#FFF",
        width: 4,
      }),
    }),
  });
};
export const arrowStyle = (scaleValue) => {
  return new Style({
    image: new Icon({
      anchor: [0.5, 1],
      anchorXUnits: "fraction",
      anchorYUnits: "fraction",
      src: imageArrow,
      displacement: [0, -28 * scaleValue],
      scale: scaleValue,
    }),
    zIndex: 0,
  });
};
export const pinCompositeStyle = (scaleValue) => (feature) => {
  if (feature && feature.clickAble) {
    const iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        src: feature.src,
        scale: scaleValue,
      }),
      zIndex: 1,
    });

    return [circleShadowStyle(scaleValue), circleStyle(scaleValue), iconStyle, arrowStyle(scaleValue)];
  } else {
    if (feature.featureType === "Polygon") return polygonStyle;
    if (feature.featureType === "LineString") return lineStringStyle;
  }
};

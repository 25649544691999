import Icons from "../Icons/Icons";
import SidenavItem from "./SidenavItem";

const formatHungarianCurrency = (string) => {
  const number = parseFloat(string).toLocaleString('hu-HU', {
    style: 'currency',
    currency: 'HUF', // Hungarian Forint
    maximumFractionDigits: 0 // Ensure no trailing decimals
  });

  return number;
};

const Sidenav = ({
  selectedInvestment,
  setSelectedInvestment,
  setGalleryIsOpen,
}) => {
  const dataObj = selectedInvestment?.dataObject;
  if (!dataObj) return <></>;

  const {
    projectName,
    galleryCollection,
    address,
    projectId,
    deliveryYear,
    totalCostLarge,
    aidAmmountLarge,
    description,
  } = dataObj;

  return (
    <div className="sidenav">
      <div className="sidenav__content">
        <div className="sidenav__content-top">
          <h3>{projectName}</h3>
          <button onClick={() => setSelectedInvestment(null)}>
            <Icons.Close />
          </button>
        </div>
        <div className="sidenav__content-middle">
          <div
            className="sidenav__content-middle__images"
            onClick={() => setGalleryIsOpen(true)}
          >
            {galleryCollection?.items.slice(0, 3).map((item, i) => (
              <img key={i} src={`${item.url}?w=420&h=300`} alt={item.title} />
            ))}
            <span>Fotógaléria</span>
          </div>
          <div className="sidenav__content-middle__details">
            <SidenavItem leftText="Cím" rightText={address} />
            <SidenavItem leftText="Projekt azonosítója" rightText={projectId} />
            <SidenavItem leftText="Átadás éve" rightText={deliveryYear} />
            <SidenavItem
              leftText="Összköltség"
              rightText={formatHungarianCurrency(totalCostLarge)}
            />
            <SidenavItem
              leftText="Támogatás összege"
              rightText={formatHungarianCurrency(aidAmmountLarge)}
              isLast
            />
          </div>
          <div className="sidenav__content-middle__desc">
            <p>{description} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidenav;

// Imports
import { fromLonLat } from 'ol/proj';

const baseViewProjection = "EPSG:3857";
const baseViewCenter = [parseFloat("21.625"), parseFloat("47.55")];
const baseViewWMCenter = fromLonLat(baseViewCenter, baseViewProjection);

export const MapConfig = {
  baseViewProjection,
  baseViewCenter,
  baseViewWMCenter,
  initZoom: 6,
  maxZoom: 19,
  maxViewExtent: () => {
    const c = baseViewWMCenter;
    const p = 25000;
    return [c[0] - p, c[1] - p, c[0] + p, c[1] + p];
  }
};
const getInvestmentCategories = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CONTENTFUL_URL}${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_TOKEN}`,
        },
        body: JSON.stringify({
          query: `
            query {
              investmentCategoryCollection {
                total
                items {
                  singularName
                  pluralName
                  icon {
                    url
                    title
                  }
                }
              }
            }
          `,
        }),
      }
    );

    const responseData = await response.json();
    return responseData.data.investmentCategoryCollection.items
  } catch (error) {
    console.error("Hiba történt a lekérdezés során:", error);
  }
};

export default getInvestmentCategories;

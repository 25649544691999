import { useEffect, useState } from "react";
import getInvestmentCategories from "../api/getInvestmentCategories";
import getInvestments from "../api/getInvestments";
import { useIsMobile } from "../utils";
import Filter from "./Filter/Filter";
import Gallery from "./Gallery/Gallery";
import OLMap from "./Map/OLMap";
import MapSwitch from "./MapSwitch/MapSwitch";
import SearchBar from "./SearchBar/SearchBar";
import Sidenav from "./Sidenav/Sidenav";

const MapView = () => {
  const [isMapTypeOrto, setIsMapTypeOrto] = useState(true);
  const [searchBounds, setSearchBounds] = useState([]);
  const [investments, setInvestments] = useState([]);
  const [selectedInvestment, setSelectedInvestment] = useState(null);
  const [categories, setCategories] = useState([]);
  const [galleryIsOpen, setGalleryIsOpen] = useState(false);
  const [clickedInvestment, setClickedInvestment] = useState(null);
  const [clickedInvestmentOutOfView, setClickedInvestmentOutOfView] = useState(false);

  const isMobile = useIsMobile();

  const filteredInvestments = categories.some((category) => category.checked)
    ? investments.filter((investment) =>
        categories.some(
          (category) =>
            category.checked &&
            category.value === investment.category.pluralName
        )
      )
    : investments;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getInvestmentCategories();
        const transformedCategories = data.map((category) => ({
          label: category.pluralName,
          value: category.pluralName,
          icon: category.icon,
          checked: false,
        }));
        setCategories(transformedCategories);
      } catch (error) {
        console.error("Hiba történt a kategóriák lekérdezése során:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchInvestments = async () => {
      try {
        const data = await getInvestments();
        setInvestments(data);
      } catch (error) {
        console.error("Hiba történt a kategóriák lekérdezése során:", error);
      }
    };

    fetchInvestments();
  }, []);

  return (
    <>
      <div
        className={`mapview${
          selectedInvestment !== null ? " open-sidenav" : ""
        }`}
      >
        <SearchBar
          setSearchBounds={setSearchBounds}
          investments={filteredInvestments}
          setClickedInvestment={setClickedInvestment}
        />
        {selectedInvestment ? (
          <></>
        ) : (
          <Filter
            categories={categories}
            setCategories={setCategories}
          />
        )}

        {isMobile && selectedInvestment ? (
          <></>
        ) : (
          <MapSwitch
            isMapTypeOrto={isMapTypeOrto}
            setIsMapTypeOrto={setIsMapTypeOrto}
          />
        )}

        <OLMap
          searchBounds={searchBounds}
          isMapTypeOrto={isMapTypeOrto}
          investments={filteredInvestments}
          categories={categories}
          selectedInvestment={selectedInvestment}
          setSelectedInvestment={setSelectedInvestment}
          clickedInvestment={clickedInvestment}
          setClickedInvestment={setClickedInvestment}
          clickedInvestmentOutOfView={clickedInvestmentOutOfView}
          setClickedInvestmentOutOfView={setClickedInvestmentOutOfView}
        />
      </div>
      <Sidenav
        selectedInvestment={selectedInvestment}
        setSelectedInvestment={setSelectedInvestment}
        setGalleryIsOpen={setGalleryIsOpen}
      />

      {galleryIsOpen && (
        <Gallery
          setGalleryIsOpen={setGalleryIsOpen}
          selectedInvestment={selectedInvestment}
        />
      )}
    </>
  );
};

export default MapView;

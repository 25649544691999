import { useState, useEffect } from "react";
import Icons from "../Icons/Icons";

const Checkbox = ({ label, icon, checked, noFilterChecked, onChange, ...props }) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = () => {
    setIsChecked(!isChecked);
    if (onChange) onChange(!isChecked);
  };

  return (
    <div className="checkbox-wrapper">
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleChange}
          className={isChecked ? "checked" : undefined}
          {...props}
        />
        {isChecked && (
          <span>
            <Icons.Check />
          </span>
        )}
        <div className={noFilterChecked ? "checked" : isChecked ? "checked" : undefined}>
          <img src={icon.url} alt={icon.title} />
          <p>{label}</p>
        </div>
      </label>
    </div>
  );
};

export default Checkbox;

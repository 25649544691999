// React imports
import React, {
  createContext,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
// OpenLayers imports
import Map from "ol/Map";
import View from "ol/View";
import { defaults as controlDefaults } from "ol/control/defaults";
import { defaults as interactionDefaults } from "ol/interaction/defaults";
import { fromLonLat, transform, transformExtent } from "ol/proj";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
// Map Config
import { MapConfig } from "./MapConfig";
// Layers import
import { osmLayer, ortoLayer, pinLayer } from "./Layers";
import { lineStringStyle, polygonStyle } from "./Styles";
// API
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import {
  deselectFeatures,
  applyIconScale,
  handleMapHover,
  setupSelectInteraction,
  zoomToFeatureExtent,
} from "./Functions";
import { LineString, MultiPolygon } from "ol/geom";
import Warning from "../Warning/Warning";
import { useIsMobile } from "../../utils";

import defaultIcon from "../../assets/images/default-icon.png";


const EPSG_23700 = "EPSG:23700";
proj4.defs(
  EPSG_23700,
  "+proj=somerc +lat_0=47.1443937222222 +lon_0=19.0485717777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=57.01,-69.97,-9.29,0,0,0,0 +units=m +no_defs +type=crs"
);
register(proj4);

// Create MapContext context
const MapContext = createContext();

const OLMap = ({
  searchBounds,
  isMapTypeOrto,
  investments,
  categories,
  selectedInvestment,
  setSelectedInvestment,
  clickedInvestment,
  setClickedInvestment,
  clickedInvestmentOutOfView,
  setClickedInvestmentOutOfView,
}) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [prevCategoriesLength, setPrevCategoriesLength] = useState(null);
  
  const [iconScale, setIconScale] = useState(0.25);
  const iconScaleRef = useRef();
  iconScaleRef.current = iconScale;

  const isMobile = useIsMobile();

  // Handle view checking
  const checkInView = useCallback(
    (view, size) => {
      const viewExtent = view.calculateExtent(size);
      const pinFeaturesInView = pinLayer
        .getSource()
        .getFeaturesInExtent(viewExtent);

      setClickedInvestmentOutOfView(
        pinFeaturesInView.length === 0 ? true : null
      );
    },
    [setClickedInvestmentOutOfView]
  );

  const getExtentCurrentItem = () => {
    const currentFeature = pinLayer.getSource().getFeatures()[0];
    if (
      (currentFeature.featureType === "LineString") |
      (currentFeature.featureType === "Polygon")
    ) {
      zoomToFeatureExtent(map, currentFeature, 80);
    } else {
      zoomToFeatureExtent(map, selectedInvestment, 80);
    }
  };

  const createNewImage = (iconImage) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const size = 36;
    canvas.width = size;
    canvas.height = size;

    context.beginPath();
    context.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
    context.closePath();
    context.clip();

    context.drawImage(
      iconImage,
      0,
      0,
      iconImage.width,
      iconImage.height,
      0,
      0,
      size,
      size
    );
    const newImage = new Image();
    newImage.src = canvas.toDataURL();
    return newImage;
  };

  // MOUNT MAP
  useEffect(() => {
    let options = {
      view: new View({
        projection: MapConfig.baseViewProjection,
        center: MapConfig.baseViewWMCenter,
        zoom: MapConfig.initZoom,
        minZoom: MapConfig.initZoom,
        maxZoom: MapConfig.maxZoom,
        extent: MapConfig.maxViewExtent(),
      }),
      layers: [osmLayer, ortoLayer, pinLayer],
      controls: controlDefaults({
        zoom: false,
        rotate: false,
      }),
      interactions: interactionDefaults({
        pinchRotate: false,
      }),
    };
    let mapObject = new Map(options);
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);

    return () => mapObject.setTarget(undefined);
  }, []);

  // --- IMAGELAYER ---
  // Handle active switchable layer
  useEffect(() => {
    if (map) {
      ortoLayer.setVisible(isMapTypeOrto);
      osmLayer.setVisible(!isMapTypeOrto);
    }
  }, [isMapTypeOrto, map]);

  // Add investments to map
  useEffect(() => {
    if (investments.length > 0 && !selectedInvestment) {
      const source = pinLayer.getSource();
      source.clear();

      Promise.all(
        investments.map((inv) => {
          return new Promise((resolve, reject) => {
            const iconImage = new Image();
            iconImage.crossOrigin = "Anonymous";

            iconImage.onload = () => {
              const newImage = createNewImage(iconImage);
              const eovCoordinates = inv.poiCoordinates;
              const convertedGPS = transform(
                eovCoordinates,
                EPSG_23700,
                "EPSG:4326"
              );
              const feature = new Feature(
                new Point(fromLonLat([convertedGPS[0], convertedGPS[1]]))
              );
              feature.dataObject = inv;
              feature.projectId = inv.projectId;
              feature.address = inv.address;
              feature.clickAble = true;
              feature.src = newImage.src;
              resolve(feature);
            };

            iconImage.onerror = reject;

            iconImage.src = inv.category.icon ? inv.category.icon.url : defaultIcon;
          });
        })
      )
        .then((featuresArray) => { 
          // Add features to map
          source.addFeatures(featuresArray);

          // Handle icon scaleing
          applyIconScale(iconScaleRef.current, source);
        })
        .then(() => {
          // Count how many categories are checked
          const checkedCategoriesCount = categories.reduce((total, item) => total + (item.checked ? 1 : 0), 0);

          if(prevCategoriesLength !== checkedCategoriesCount) {
            // Zoom map to categories extent
            const featuresExtent = source.getExtent();
            map.getView().fit(featuresExtent, {
              padding: [120, 120, 120, 120],
              duration: 400
            });

            // Set the new value
            setPrevCategoriesLength(checkedCategoriesCount);
          }
        })
        .catch((error) => {
          console.error("Error loading images:", error);
        });
    }
  }, [investments, selectedInvestment, clickedInvestment, categories, prevCategoriesLength, map]);

  // Handle selectedInvestment changes
  useEffect(() => {
    if (!map || !selectedInvestment) return;

    const geometryType = selectedInvestment.dataObject?.gisData?.type;

    if (geometryType) {
      const source = pinLayer.getSource();

      if (geometryType === "GeometryCollection") {
        source.clear();
        const coordinates =
          selectedInvestment.dataObject.gisData.geometries[0].coordinates;
        const lineStringGeometry = new LineString(
          coordinates.map((coord) => {
            const transformedCoord = transform(coord, EPSG_23700, "EPSG:3857");
            return transformedCoord;
          })
        );
        const lineStringFeature = new Feature({
          geometry: lineStringGeometry,
        });
        lineStringFeature.clickAble = false;
        lineStringFeature.featureType = "LineString";
        lineStringFeature.setStyle(lineStringStyle);
        source.addFeature(lineStringFeature);
        zoomToFeatureExtent(map, lineStringFeature, 80);
      }
      if (geometryType === "FeatureCollection") {
        source.clear();
        const allPolygonsCoordinates = [];

        selectedInvestment.dataObject.gisData.features.forEach((feature) => {
          if (feature.geometry.type === "Polygon") {
            const polygonCoordinates = feature.geometry.coordinates[0];
            allPolygonsCoordinates.push(
              polygonCoordinates.map((coord) =>
                transform(coord, EPSG_23700, "EPSG:3857")
              )
            );
          }
        });

        const multiPolygonGeometry = new MultiPolygon(
          allPolygonsCoordinates.map((coordinates) => [coordinates])
        );
        const multiPolygonFeature = new Feature({
          geometry: multiPolygonGeometry,
        });
        multiPolygonFeature.clickAble = false;
        multiPolygonFeature.featureType = "Polygon"; 
        multiPolygonFeature.setStyle(polygonStyle);
        source.addFeature(multiPolygonFeature);
        zoomToFeatureExtent(map, multiPolygonFeature, 80);
      }
    } else {
      zoomToFeatureExtent(map, selectedInvestment, 80);
    }
  }, [map, selectedInvestment]);

  // Handle Search Zooming
  useEffect(() => {
    if (!map || !searchBounds.length) return;

    const transSearchBounds = transformExtent(
      searchBounds,
      "EPSG:4326",
      "EPSG:3857"
    );

    // Zoom to bounds
    map.getView().fit(transSearchBounds, { duration: 400 });
  }, [map, searchBounds]);

  useEffect(() => {
    if (!map && clickedInvestment === null) return;

    if (map && clickedInvestment && pinLayer) {
      const eovCoordinates = clickedInvestment.poiCoordinates;
      const convertedGPS = transform(eovCoordinates, EPSG_23700, "EPSG:4326");
      const feature = new Feature(
        new Point(fromLonLat([convertedGPS[0], convertedGPS[1]]))
      );
      feature.dataObject = clickedInvestment;
      setSelectedInvestment(feature);
      zoomToFeatureExtent(map, feature, 80);
      setTimeout(() => {
        setClickedInvestment(null);
      }, 1000);
    }
  }, [map, clickedInvestment, setClickedInvestment, setSelectedInvestment]);

  // Map hover events
  useEffect(() => {
    if (map) {
      handleMapHover(map);

      // Map MOVEMENT handling
      map.on("moveend", () => {
        if (selectedInvestment) {
          const view = map.getView();
          const size = map.getSize();
          checkInView(view, size);
        }
      });
    }
  }, [map, checkInView, selectedInvestment]);

  useEffect(() => {
    if (map && pinLayer) {
      const selectInteraction = setupSelectInteraction(
        map,
        pinLayer,
        setSelectedInvestment
      );
      return () => {
        if (map) {
          map.removeInteraction(selectInteraction);
        }
      };
    }
  }, [map, setSelectedInvestment]);

  // Deselect feature
  useEffect(() => {
    if (map && selectedInvestment === null) {
      deselectFeatures(map);
    }
  }, [map, selectedInvestment]);

  // Event listener for zoom change
  useEffect(() => {
    if (map) {
      const handleIconScaleChange = () => {
        const zoomLevel = map.getView().getZoom();
        let newIconScale = 1;
  
        if (zoomLevel < 14) {
          newIconScale = 0.25;
        } else if (zoomLevel >= 14 && zoomLevel < 16) {
          newIconScale = 0.5;
        } else if (zoomLevel >= 16 && zoomLevel < 18) {
          newIconScale = 0.75;
        }
  
        // Only update the style and state if the icon scale has changed
        if (newIconScale !== iconScale) {
          const source = pinLayer.getSource();
          applyIconScale(newIconScale, source);
          setIconScale(newIconScale);
        }
      };
  
      map.getView().on('change:resolution', handleIconScaleChange);
  
      // Clean up the event listener when the component unmounts or map changes
      return () => {
        map.getView().un('change:resolution', handleIconScaleChange);
      };
    }
  }, [map, iconScale]);

  useEffect(() => {
    if (map) {
      map.updateSize();
    }
  }, [selectedInvestment, map]);

  return (
    <MapContext.Provider value={{ map: map }}>
      <div ref={mapRef} className={"mapview__map"} />
      {clickedInvestmentOutOfView && selectedInvestment && !isMobile && (
        <Warning onClick={getExtentCurrentItem} />
      )}
    </MapContext.Provider>
  );
};

export default OLMap;

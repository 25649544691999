// OpenLayers imports
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import VectorSource from "ol/source/Vector";
import { Vector as VectorLayer } from "ol/layer";
import { transformExtent } from "ol/proj";


// Proj4
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { pinCompositeStyle } from "./Styles";

// EPSG:23700 used by the availabilityLayer JSON data
proj4.defs(
  "EPSG:23700",
  "+proj=somerc +lat_0=47.1443937222222 +lon_0=19.0485717777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=57.01,-69.97,-9.29,0,0,0,0 +units=m +no_defs +type=crs"
);
register(proj4);

// OSM BACKGROUND LAYER
export const osmLayer = new TileLayer({
  source: new XYZ({
    url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  }),
  name: "osmLayer",
  className: "bw-layer",
  opacity: 0.6,
});
// ORTO LAYERS
export const ortoLayer = new TileLayer({
  source: new XYZ({
    url: "https://tileserver{1-8}.envimap.hu/2023_Debrecen/{z}/{x}/{y}",
    crossOrigin: "Anonymous",
    minZoom: 8,
    maxZoom: 18,
  }),
  name: "topoOrtoLayer",
  extent: transformExtent(
    [21.450936,47.383822,21.850891,47.63821],
    "EPSG:4326",
    "EPSG:3857"
  ),
});
// PIN LAYER
export const pinLayer = new VectorLayer({
  source: new VectorSource(),
  style: pinCompositeStyle(0.25),
  name: "pinLayer",
});

import throttle from "lodash.throttle";
import Select from "ol/interaction/Select.js";
import { pinCompositeStyle } from "./Styles";

// Animate view to features extent
export const zoomToFeatureExtent = (map, feature, padding) => {
  const extent = feature.getGeometry().getExtent();
  const view = map.getView();

  view.fit(extent, {
    padding: [padding, padding, padding, padding],
    duration: 400,
  });
};

let select = null;

export const setupSelectInteraction = (
  map,
  pinLayer,
  setSelectedInvestment
) => {
  if (select !== null) {
    map.removeInteraction(select);
  }

  select = new Select({
    layers: [pinLayer],
    style: pinCompositeStyle,
  });

  if (select !== null) {
    map.addInteraction(select);

    select.on("select", (e) => {
      const feature = e.selected[0];

      if (feature && feature.clickAble) {
        setSelectedInvestment(feature);
      }
    });
  } else {
    deselectFeatures(map);
  }
};

// Deselect features
export const deselectFeatures = (map) => {
  
  // Slightly zoom out to indicate the close action
  const view = map.getView();
  const currentZoom = view.getZoom();
  view.animate({
    zoom: currentZoom - 1,
    duration: 400,
  });

  // Clear features
  if (select) {
    select.getFeatures().clear();
    map.dispatchEvent(select);
  }
};

// Map hover events
export const handleMapHover = (map) => {
  map.on(
    "pointermove",
    throttle((e) => {
      const getFeature = (pixel) =>
        map.forEachFeatureAtPixel(pixel, (feature) => {
          return feature.clickAble;
        });

      map.getViewport().style.cursor = getFeature(e.pixel)
        ? "pointer"
        : "initial";
    }, 100)
  );
};

// Handle icon scale apply
export const applyIconScale = (iconScale, source) => {
  source.getFeatures().forEach((feature) => {
    feature.setStyle(pinCompositeStyle(iconScale));
  });
};
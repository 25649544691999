import { useState } from "react";
import Checkbox from "../Checkbox/Checkbox";
import Icons from "../Icons/Icons";

const Filter = ({ categories, setCategories }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const handleCheckboxChange = (label) => {
    setCategories((prevCategories) => {
      return prevCategories.map((category) => {
        if (category.label === label) {
          return {
            ...category,
            checked: !category.checked,
          };
        }
        return category;
      });
    });
  };

  return (
    <div className="filter">
      <div>
        <button onClick={() => setIsFilterOpen((prev) => !prev)}>
          <div>
            <span>Szűrés</span>
            {!isFilterOpen && (
              <>
                {categories.filter((item) => item.checked).length === 0 ? (
                  <span>Minden beruházás</span>
                ) : (
                  <span>
                    {categories.filter((item) => item.checked).length} kategória
                    kiválasztva
                  </span>
                )}
              </>
            )}
          </div>
          <div className={`icon${isFilterOpen ? " open" : ""}`}>
            <Icons.ArrowDown />
          </div>
        </button>
      </div>
      {isFilterOpen && (
        <>
          <hr />
          <div className="filter__checkboxes">
            {categories.map((category, index) => (
              <Checkbox
                key={index}
                label={category.label}
                icon={category.icon}
                checked={category.checked}
                noFilterChecked={categories.filter((item) => item.checked).length === 0}
                onChange={() => handleCheckboxChange(category.label)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Filter;

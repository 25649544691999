const SearchBarInvestment = ({
  item,
  setClickedInvestment,
  setValue,
  setSearchOpen,
}) => {
  const { address, projectName, galleryCollection, category } = item;
  const imgURL = galleryCollection.items.length ? galleryCollection.items[0].url : category.icon.url;

  const handleSelect = (item) => {
    setValue("");
    setTimeout(() => {
      setSearchOpen(false);
      setClickedInvestment(item);
    }, 100);
  };

  return (
    <div className="search-investment" onClick={() => handleSelect(item)}>
      <img src={imgURL} alt="Investment item" />
      <div>
        <span>{projectName}</span>
        <span>{address}</span>
      </div>
    </div>
  );
};

export default SearchBarInvestment;

import Icons from "../Icons/Icons";

const Warning = ({onClick}) => {
  return (
    <div className="warning" onClick={onClick}>
      <Icons.Pin />
      <span>Vissza a beruházásra</span>
    </div>
  );
};

export default Warning;
